import React from "react";
import "./styles.css";

const FormIntro = () => (
  <header className="home-header">
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/iziaLos2.png" alt="event1" />
    </div>
    <br />
    <p>
      Behind every event is the dedication and hard work of our community, all driven by volunteers who give their time and energy to make it happen.
      <br />
      From planning trips and organizing meetups to handling the countless details that bring everything together, it’s a collective effort fueled by a shared passion.
      <br />
      <br />

      By becoming an official member, you’re helping to support this organization and all the activities it offers.
      <br />
      Membership costs just <b>105 NOK per year</b>, and your contribution goes directly toward sustaining and growing our initiatives.
      <br />
      As a member, you’re not just participating—you’re actively supporting a community built on collaboration and generosity.
      <br />
      <br />

      To learn more about membership benefits and additional promotions available to members, visit this
      <b><a href="/members"> page. </a></b>
      <br />
      <br />

      The excitement of planning, the shared anticipation, and the joy of seeing it all come together have been as memorable as the events themselves.
      <br />
      <br />
      Whether organizing trips, coordinating meetups, or simply lending a helping hand, the spirit of collaboration within our group shines brightly.
      <br />
      <b>NOTE:</b> <b>This form is in the testing phase. Please do not make any payments at this time.</b>
      <br />
    </p>
  </header>
);

export default FormIntro;