import React from "react";
import "./styles.css";

const FormTerms = () => (
  <section className="home-header">

    <p>
      <b>Terms & Conditions</b> <br /><br />
      By purchasing a membership, you agree to the following terms: <br /><br />

      <b>Membership Validity:</b> Your membership is valid for <b>one year</b> from the date of payment. It does not renew automatically. <br />
      <b>Payment & Confirmation:</b> The membership fee of <b>105 NOK</b> is a one-time payment for a one-year period. Payment must be completed to activate membership. <br />
      <b>Cancellation & Refunds:</b> Membership fees are <b>non-refundable</b>. You can cancel your membership at any time, but this will not result in a refund. <br />
      <b>Renewal Reminder:</b> A notification will be sent before the expiration of your membership to remind you to renew. <br />
      <b>Termination:</b> The organization reserves the right to revoke a membership if a member violates community guidelines or acts against the interests of the organization. <br />
      <b>Contact for Cancellation & Inquiries:</b> If you wish to cancel your membership or have any questions, please contact us at <b>ladiesofstavanger@gmail.com</b>. <br /><br />

      For full details, please refer to the <b>Standard Sales Terms for Consumer Purchases Online</b> as recommended by the Norwegian Consumer Authority: <br />
      <a href="https://www.forbrukertilsynet.no/lov-og-rett/veiledninger-og-retningslinjer/standard-salgsbetingelser-for-forbrukerkjop-av-varer-over-internett" target="_blank" rel="noopener noreferrer">
        Norwegian Consumer Authority Guidelines
      </a>
    </p>
  </section>
);

export default FormTerms;
