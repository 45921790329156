import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Blog from './pages/Blog';
import Home from './pages/Home';
import About from './pages/About';
import Members from './pages/Members';
import News from './pages/News';
import Form from './pages/Form';
import Navbar from './components/Home/Navbar';
import Footer from './components/Home/Footer';


const App = () => {
  return (
    <div className='container2'>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/blog/:id' element={<Blog />} />
        <Route path='/about' element={<About />} />
        <Route path='/members/' element={<Members />} />
        <Route path='/news' element={<News />} />
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/form' element={<Form />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;