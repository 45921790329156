import React from "react";
import "./styles.css";

const ShortAbout = () => (
  <div className="text-center">
    <div className="container">
      <div className="about-content">
        <h2>About Us</h2>
        <div className="row">
          <div className="col-md-8">
            <p>
              The Ladies of Stavanger is a group founded during the pandemic by
              women from different countries who wanted to make new friends.
              Four women came together with the goal of meeting like-minded
              individuals, participating in fun activities, and sharing their
              experiences of living in a foreign town away from their families.
              Over time, the group has grown to include more than three thousand
              members and has become an inclusive community catering to expat
              and local Norwegian women.
            </p>
            <br />
            <p>
              We invite you to join us for our monthly events, sports
              activities, and social media platform, where you can connect with
              a diverse group of fun and friendly women of all ages,
              backgrounds, and races. Whether you're new to Stavanger or a
              long-time resident, The Ladies of Stavanger is a great way to meet
              new people and make lasting friendships.
            </p>
          </div>
          <div className="col-md-4">
            <img
              className="Los-img"
              src="https://profitbasedocs.blob.core.windows.net/other/front.jpg"
              alt="group photo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ShortAbout;
