import React from "react";
import "./styles.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="nav">
      <Link to="/" className="site-title">
        <img
          src="https://profitbasedocs.blob.core.windows.net/other/logoA.png"
          alt=""
        />
      </Link>
      <ul>
        <CustomLink to="/">Home</CustomLink>
        <CustomLink to="/news">What's New</CustomLink>
        <CustomLink to="/about">About</CustomLink>
        <CustomLink to="/members">Members</CustomLink>
        <CustomLink to="/form">Join</CustomLink>
      </ul>
    </nav>
  );
}

export function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
