import React from "react";
import FormIntro from "../../components/Home/FormIntro";
import FormComponent from "../../components/Home/Form"; // Renamed for clarity
import FormTerms from "../../components/Home/FormTerms"; // Import FormTerms

const HomePage = () => {
  return (
    <div>
      {/* Form Intro */}
      <FormIntro />
      
      {/* Form */}
      <FormComponent />
      
      {/* Terms & Conditions */}
      <FormTerms />
    </div>
  );
};

export default HomePage;

