import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./styles.css";

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  fullName: Yup.string().required("Required"),
  nationality: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  dateOfBirth: Yup.date().required("Required"),
});

const MyForm = () => {
  return (
    <Formik
      initialValues={{
        email: "",
        fullName: "",
        nationality: "",
        address: "",
        dateOfBirth: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // Inicjowanie płatności Vipps przed zapisem do bazy
        axios
          .post("http://localhost:5000/initiate-payment", values)
          .then((response) => {
            if (response.data.url) {
              // Przekierowanie do bramki płatniczej Vipps
              window.location.href = response.data.url;
            } else {
              alert("Błąd podczas inicjowania płatności!");
            }
          })
          .catch((error) => {
            alert("Błąd podczas łączenia z systemem płatności!");
            console.error(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-container">
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">
              Full Name *
            </label>
            <Field
              id="fullName"
              name="fullName"
              placeholder="Your full name"
              className="form-input"
            />
            {errors.fullName && touched.fullName ? (
              <div className="form-error">{errors.fullName}</div>
            ) : null}
          </div>

          {/* E-mail */}
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email Address *
            </label>
            <Field
              id="email"
              name="email"
              type="email"
              placeholder="Your email"
              className="form-input"
            />
            {errors.email && touched.email ? (
              <div className="form-error">{errors.email}</div>
            ) : null}
          </div>

          {/* Nationality */}
          <div className="form-group">
            <label htmlFor="nationality" className="form-label">
              Nationality *
            </label>
            <Field
              id="nationality"
              name="nationality"
              placeholder="Your nationality"
              className="form-input"
            />
            {errors.nationality && touched.nationality ? (
              <div className="form-error">{errors.nationality}</div>
            ) : null}
          </div>

          {/* Address */}
          <div className="form-group">
            <label htmlFor="address" className="form-label">
              Address *
            </label>
            <Field
              id="address"
              name="address"
              placeholder="Your address"
              className="form-input"
            />
            {errors.address && touched.address ? (
              <div className="form-error">{errors.address}</div>
            ) : null}
          </div>

          {/* Date of Birth */}
          <div className="form-group">
            <label htmlFor="dateOfBirth" className="form-label">
              Date of Birth *
            </label>
            <Field
              id="dateOfBirth"
              name="dateOfBirth"
              type="date"
              className="form-input"
            />
            {errors.dateOfBirth && touched.dateOfBirth ? (
              <div className="form-error">{errors.dateOfBirth}</div>
            ) : null}
          </div>

            {/* Submit button */}
            <button type="submit" className="form-button" disabled>
              Submit and pay (Currently Disabled)
            </button>
        </Form>
      )}
    </Formik>
  );
};

export default MyForm;
