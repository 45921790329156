export const teamData = [
  {
    name: "Izabela Buczynska",
    job: "CEO/Executive",
    img: "https://profitbasedocs.blob.core.windows.net/other/Iza.jpg",
  },
  {
    name: "Aashima Budal",
    job: "Development",
    img: "https://profitbasedocs.blob.core.windows.net/other/Aashima.jpg",
  },
  {
    name: "Roma Nice-Vaisvile",
    job: "Operations",
    img: "https://profitbasedocs.blob.core.windows.net/other/Roma.jpg",
  },
];
